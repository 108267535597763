<template>
  <div>
    <div class="right-a">
      <!-- 头像上传 -->
      <div class="a">
        <Card :parent_id="parent_id" style="margin-bottom: 31px">
          <p class="ti">我的相册</p>
        </Card>
        <div class="album">
          <div class="title-box">
            <p class="title-h">上传形象照</p>
            <p>请选择清晰美观的个人近照，作为你的形象照</p>
            <p>·确保五官清晰，正面、微侧皆可。勿上传裸照、模糊或他人照片；</p>
            <p>·照片尺寸需大于100x100像素，最大不超过10M。</p>
          </div>
          <div class="box">
            <div class="cropper-box">
              <div class="upload-btn" v-show="!caiJian">
                <p>
                  有照片用户的关注度、收信率
                  <br />
                  是无头像用户的 <span>7倍</span> ！
                </p>
                <div class="BTN-box">
                  <el-upload
                    action
                    :show-file-list="false"
                    :http-request="avatarUpload"
                    drag
                  >
                    <div class="Btn">从电脑选取照片</div>
                  </el-upload>
                </div>
              </div>
              <div class="containerLL" v-show="caiJian">
                <div class="img-container">
                  <img id="imageLL" :src="caiJianImg" ref="imageLL" alt="" />
                </div>
              </div>
              <!-- 裁剪 -->
            </div>
            <div class="preview-box">
              <div id="before" class="beforeL beforeA"></div>
              <div id="before" class="beforeL"></div>

              <div id="before" class="beforeL beforeB"></div>
            </div>
          </div>
          <div class="BTN">
            <div class="BTN-box" @click="sureSava">保存</div>
          </div>
        </div>
      </div>
      <div class="c"></div>
      <!-- 右 -->
      <div class="b">
        <p class="b-title">扫描快速完成照片上传</p>
        <!-- 二维码 -->
        <img
          class="codeimg"
          src="../../../assets/images/logo/logo.png"
          alt=""
        />
        <div class="b-success">
          <i class="icon"></i>
          <!-- <a>照片上传完整指南</a> -->
          <p>正确示范</p>
        </div>
        <div class="demonstrationImg">
          <img src="../../../assets/images/banner/boy.png" alt="" />
          <img src="../../../assets/images/banner/gril.png" alt="" />
        </div>
        <div class="b-fail">
          <i class="icont"></i>
          <!-- <a>照片上传完整指南</a> -->
          <p>正确示范</p>
        </div>
        <div class="demonstrationImg">
          <img src="../../../assets/images/banner/enoor.png" alt="" />
          <img src="../../../assets/images/banner/enoor-1.png" alt="" />
          <img src="../../../assets/images/banner/enoor-2.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//引入图片裁剪
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import Card from "@/components/Card";
import local from "@/api/common/local.js";
import { infocur } from "@/api/login/login.js";
import { client, getFileNameUUID } from "@/api/common/oss/oss.js"; //client,getFileNameUUID是前面的oss.js文件内的两个封装函数
import { ossL } from "@/api/common/oss/ossL.js";
import {
  albumAdd,
  albumList,
  subTk,
  approveUpdate,
} from "@/api/member/member.js";
export default {
  data() {
    return {
      parent_id: "", //组件
      // showProgress: false, //进度条的显示
      dataObj: {}, //存签名信息
      // progress: 0, //进度条数据
      imageUrl: "",
      o: 1,
      Img: "",
      // disabled: "",
      avatar: "",
      // Bavatar: "",
      picValue: "",
      cropper: "",
      croppable: false,
      panel: false,
      url: "",
      BtnBox: false,
      caiJian: false,
      caiJianImg: "",
      myCropper: null,
      newImg: "",
      //上传
      // ImgList: [],
      // fileList: [], //文件列
      // numLL: 15,
      // IIList: [],
      iSvip: "",
      Istx: "",
      // html: null,
    };
  },
  components: {
    Card,
  },
  created() {
    this.cs();
    this.tkL();
  },
  mounted() {},
  methods: {
    kaihui() {
      let rel = this.$router.resolve({
        path: "/n/yezaiMember",
      });
      window.open(rel.href, "_blank");
    },
    //初始化数据
    async cs() {
      let a = local.get("access_token");
      //获取当前用户信息头像,相册
      const { code, data } = await infocur(a);
      if (code == 0) {
        this.avatar =  ossL(data.user.avatar)
        this.iSvip = data.user.isVipMember;
        this.Istx = data.user.isAvatar;
        this.parent_id = data.user.id;
      }
    },
    async Url(img) {
      this.o = 2;
      this.imageUrl = ossL(img);
      this.Img = img;
    },
    init() {
      const Image = document.getElementById("imageLL");
      this.myCropper = new Cropper(Image, {
        viewMode: 1,
        dragMode: "none",
        initialAspectRatio: 1,
        aspectRatio: 1,
        preview: "#before",
        background: false,
        autoCropArea: 1,
        zoomOnWheel: false,
      });
    },
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    async Btn() {
      const A = await albumList();
      if (A.code == 0) {
        if (A.data.length == 0) {
          // console.log("555");
          let d = {
            avatar: this.Img,
          };
          const { code } = await approveUpdate(d);
          if (code == 0) {
            this.o = 1;
            this.imageUrl = "";
            this.$message({
              message: "上传成功",
              type: "success",
              offset: 100,
            });
            this.tkL();
            // this.state = false;
          }
        } else {
          let d = {
            imgUrls: this.Img,
          };
          const { code, data } = await albumAdd(d);
          if (code == 0) {
            console.log("------", data);
            this.o = 1;
            this.imageUrl = "";
            this.$message({
              message: "上传成功",
              type: "success",
              offset: 100,
            });
            this.tkL();
            // this.state = false;
          }
        }
      }
    },
    //头像上传
    avatarUpload(file) {
      const isLtSize = file.file.size / 1024 / 1024 < 2;
      const isJPG =
        file.file.type === "image/jpg" ||
        file.file.type === "image/png" ||
        file.file.type === "image/jpeg";
      // console.log('数据',isLtSize,file);
      if (!isLtSize) {
        // this.$message.error("");
        return this.$message({
          message: "上传图片大小不能超过 2MB!",
          type: "error",
          offset: 100,
        });
      }
      if (!isJPG) {
        // this.$message.error("");
        return this.$message({
          message: "上传图片只能为jpg或png格式",
          type: "error",
          offset: 100,
        });
      }
      const that = this;
      that.BtnBox = true;
      function multipartUpload() {
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = getFileNameUUID() + "." + fileFormat;
        client(that.dataObj)
          .multipartUpload(
            process.env.NODE_ENV === "production"
              ? 
              `prod/avatar/${fileName}`
              : `dev/avatar/${fileName}`
              ,
            file.file,
            // {
            //   //defaultAvater/
            //   headers: {
            //     "Content-Type": "img/jpg",
            //   },
            // }
          )
          //测试
          // client
          //   // 这里之所以要拼接  是因为需要上传到after-sale-img文件目录中
          //   .put(
          //     process.env.NODE_ENV === "production"
          //       ? `prod/avatar/${fileName}`
          //       : `dev/avatar/${fileName}`,
          //     file.file
          //   ) //测试
          .then(async (res) => {
            that.newImg = res.name;
            that.Img =  ossL(res.name);
            that.caiJianImg =  ossL(res.name);
            that.caiJian = true;
            const { code, data } = await subTk();
            if (code == 0) {
              that.init();
              local.SessionSet("sub_tk", data);
            }
          })
          .catch((err) => {
            console.log("err:", err);
          });
      }
      multipartUpload();
      // that.init()
    },

    // 保存头像
    async sureSava() {
      const that = this;
      //裁剪图片
      this.afterImg = this.myCropper
        .getCroppedCanvas({
          imageSmoothingQuality: "high",
        })
        .toDataURL("image/png");
      //删除之前原图
      try {
        // 填写Object完整路径。Object完整路径中不能包含Bucket名称。 ,{ dataType: 'jsonp',}
        let result = await client().delete(`${that.newImg}`);
        console.log("0-0", result);
      } catch (e) {
        // console.log("0-1", e);
      }
      // console.log('0---===',this.afterImg);
      const file = this.base64toFile(this.afterImg, getFileNameUUID());
      const baseToFile = this.base64toFile(
        this.afterImg,
        getFileNameUUID()
      ).name;
      // console.log("00", baseToFile);
      // this.base64toFile(this.afterImg, getFileNameUUID())
      // console.log("裁剪3", file);
      // 上传裁剪的图片
      function multipartUpload() {
        // let temporary = file.file.name.lastIndexOf(".");
        // let fileNameLength = file.file.name.length;
        // let fileFormat = file.file.name.substring(
        //   temporary + 1,
        //   fileNameLength
        // );
        // let fileName = getFileNameUUID() + "." + fileFormat;
        client(that.dataObj)
          .multipartUpload(
            process.env.NODE_ENV === "production"
              ?
               `prod/avatar/${baseToFile}`
              : `dev/avatar/${baseToFile}`
              ,
            file,
            // {
            //   headers: {
            //     "Content-Type": "img/jpg",
            //   },
            // }
          )
          //测试
          //         client
          // // 这里之所以要拼接  是因为需要上传到after-sale-img文件目录中
          //   .put(    process.env.NODE_ENV === "production"
          //     ? `prod/avatar/${baseToFile}`
          //     : `dev/avatar/${baseToFile}`, file)//测试
          .then(async (res) => {
            //上传成功返回值，可针对项目需求写其他逻辑
            // console.log("头像", res.name);
            // that.newImg = res.name;
            // that.Img = client().signatureUrl(res.name);
            // that.caiJianImg = client().signatureUrl(res.name);
            // that.caiJian = true;
            // const { code, data } = await subTk();
            // if (code == 0) {
            //   that.init();
            //   local.SessionSet("sub_tk", data);
            // }
            let a = {
              avatar: res.name,
            };
            that.tkL();
            const { code } = await approveUpdate(a);
            if (code == 0) {
              // that.init()
              //删除阿里云图片
              // try {
              //   // 填写Object完整路径。Object完整路径中不能包含Bucket名称。 ,{ dataType: 'jsonp',}
              //   let result = await client().delete(`${that.Bavatar}`);
              //   console.log("0-0", result);
              // } catch (e) {
              //   console.log("0-1", e);
              // }
              // that.tkL();
              that.caiJian = false;
              // that.state = false;
              that.cs();
              that.$message({
                message: "上传头像成功",
                type: "success",
                offset: 100,
              });
              // setTimeout(function () {
              // window.location.reload(); //03
              // that.$router.push("/n/user/_album");

              let rel = that.$router.resolve({
                path: "/n/user/_album",
              });
              window.open(rel.href, "_self");
              // }, 1000);
            }
          })
          .catch((err) => {
            console.log("err:", err);
          });
      }
      multipartUpload();
    },
    genghuan() {
      // location.reload();
      // this.state = true;
    },

    /**
     * @description: base64位图片转码文件流
     * @param {type}
     * @Date: 2021-07-25 09:57:22
     */
    base64toFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
    getObjectURL(file) {
      var url = null;
      if (window.createObjectURL != undefined) {
        // basic
        url = window.createObjectURL(file);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(file);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file);
      }
      return url;
    },
    change(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.panel = true;
      this.picValue = files[0];
      this.url = this.getObjectURL(this.picValue);
      //每次替换图片要重新得到新的url
      if (this.cropper) {
        this.cropper.replace(this.url);
      }
      this.panel = true;
    },

    crop() {
      this.panel = false;
      var croppedCanvas;
      var roundedCanvas;
      if (!this.croppable) {
        return;
      }
      // Crop
      croppedCanvas = this.cropper.getCroppedCanvas();
      console.log(this.cropper);
      // Round
      roundedCanvas = this.getRoundedCanvas(croppedCanvas);
      this.headerImage = roundedCanvas.toDataURL();
      this.postImg();
    },
    getRoundedCanvas(sourceCanvas) {
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      var width = sourceCanvas.width;
      var height = sourceCanvas.height;
      canvas.width = width;
      canvas.height = height;
      context.imageSmoothingEnabled = true;
      context.drawImage(sourceCanvas, 0, 0, width, height);
      context.globalCompositeOperation = "destination-in";
      context.beginPath();
      context.arc(
        width / 2,
        height / 2,
        Math.min(width, height) / 2,
        0,
        2 * Math.PI,
        true
      );
      context.fill();
      return canvas;
    },
    postImg() {
      //这边写图片的上传
    },

    //废弃代码
    insertStr(soure, start, newStr) {
      return soure.slice(0, start) + newStr + soure.slice(start);
    },
    validationStart(str, appoint) {
      str = String(str); //不区分大小写：全部转为小写后进行判断
      var char = str.substr(0, appoint.length); //从0开始，往后截取特定字符长度
      if (char == appoint) {
        //两者相同，则代表验证通过
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 500px;
  }
}
.right-a {
  // height: 700px;
  height: 787px;
  display: flex;
  position: relative;
  justify-content: space-between;
  .a {
    border-radius: 4px;
    width: 560px;
    // height: 700px;
    // height: 700px;
    height: 787px;
    margin-right: 10px;
    border-radius: 4px;

    .album {
      position: relative;
      .title-box {
        position: relative;
        margin-bottom: 30px;
        height: 80px;
        .title-h {
          font-weight: 500;
          line-height: 14px;
          margin-bottom: 8px;
        }
        p {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
        }
        p:nth-child(1) {
          height: 20px;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
        }
        p:nth-child(2) {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
        }
        p:nth-child(3),
        p:nth-child(4) {
          height: 17px;
          font-size: 12px;
          font-weight: 400;
          color: #adadad;
          line-height: 17px;
        }
        div {
          margin-top: 2px;
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 17px;
        }
        .title-btn {
          cursor: pointer;
          position: absolute;
          right: 0;
          bottom: 0;
          margin-left: 167px;
          .BTN-box {
            width: 90px;
            height: 40px;
            overflow: hidden;
            border-radius: 4px;
            height: 40px;
            line-height: 40px;
            white-space: nowrap;
            padding: 0 20px;
            text-align: center;
            color: #fff;
            background: #fd686e;
          }
        }
      }
      .box {
        margin-top: 10px;
        display: flex;
        .cropper-box {
          width: 370px;
          height: 370px;
          background: #f2f3f5;
          border: 2px solid #ebebeb;
          position: relative;
          .upload-btn {
            img {
              width: 370px;
              height: 370px;
            }
            .upload {
              width: 16px;
              height: 16px;
              vertical-align: middle;
              padding-right: 2px;
              .upload-btn .upload-img {
                position: absolute;
                top: 0;
                left: 0;
                width: 200px;
                height: 40px;
              }
            }
            p {
              height: 40px;
              margin-top: 130px;
              margin-bottom: 20px;
              text-align: center;
              font-size: 14px;
              color: #666666;
              line-height: 20px;
              span {
                color: #fd686e;
              }
            }
            .BTN-box {
              cursor: pointer;
              border-radius: 4px;
              overflow: hidden;
              user-select: none;
              font-size: 14px;
              cursor: pointer;
              /deep/ .el-upload-dragger {
                background: none;
                border: none;
                display: flex;
                height: 50px;
                justify-content: center;
                .Btn {
                  width: 200px;
                  height: 40px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: #ffffff;
                  background: #fd686e;
                  border-radius: 4px;
                }
              }

              .avatar-uploader .el-upload {
                cursor: pointer;
                position: relative;
                overflow: hidden;
              }
              .avatar {
                display: block;
              }
            }
          }
          .containerLL {
            width: 370px;
            height: 370px;
            display: flex;
            align-items: center;
            img {
              width: 100%;
              height: 100%;
            }
            .img-container {
              width: 370px;
              height: 370px;
              overflow: hidden;
            }
            .afterCropper {
              flex: 1;
              margin-left: 20px;
              border: 1px solid salmon;
              text-align: center;
            }
            .afterCropper img {
              width: 150px;
              margin-top: 30px;
            }
          }
        }

        .preview-box {
          margin-left: 24px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .beforeL {
            // background-color: aliceblue;
            background: #f2f3f5;
            border: 2px solid #ebebeb;
            width: 110px;
            height: 110px;
            box-sizing: border-box;
            overflow: hidden;
            /* 这个属性可以得到想要的效果 */
          }
          .beforeA {
            width: 140px !important;
            height: 140px !important;
            // margin: 10px 0px;
            // margin-bottom: 10px;
            overflow: hidden;
            /* 这个属性可以得到想要的效果 */
          }
          .beforeB {
            width: 80px !important;
            height: 80px !important;
            // margin: 10px 0px;
            overflow: hidden;
            /* 这个属性可以得到想要的效果 */
          }
        }
      }

      .album-cx {
        text-align: right;
        width: 90px;
        height: 40px;
        margin-left: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fd686e;
        color: #ffffff;
        border-radius: 4px;
        cursor: pointer;
      }
      .BTN {
        margin-top: 16px;
        display: flex;
        justify-content: center;
        .BTN-box {
          width: 200px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          color: #ffffff;
          font-size: 14px;
          background: #fd686e;
          border-radius: 20px;
        }
      }
    }
  }
  .c {
    flex: 1;
    background-color: #fafafaff;
  }
  .b {
    position: absolute;
    right: -55px;
    // right: -336px;
    width: 298px;
    // height: 700px;
    height: 787px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #ffffff;
    .b-title {
      align-self: center;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 25px;
    }
    .codeimg {
      width: 180px;
      height: 180px;
      align-self: center;
      margin-top: 15px;
    }
    .b-success {
      display: flex;
      align-items: center;
      margin-top: 32px;
      a {
        width: 96px;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fd686e;
        line-height: 17px;
        text-decoration: underline;
      }
      .icon {
        display: flex;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-image: url(../../../assets/images/tubiao/check.b69581a.png);
      }
    }
    .b-fail {
      display: flex;
      align-items: center;
      margin-top: 32px;
      .icont {
        display: flex;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-image: url(../../../assets/images/tubiao/error.83ae3e4.png);
      }
    }
    .demonstrationImg {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>